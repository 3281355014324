import AdsApi from '@/services/api/Ads';
import _ from 'lodash';

const state = () => ({
    adArray: [],
    selectedAd: null,
    loading: false,
    pollingTimer: null,
});

const getters = {
    summarySortedAds(state) {
        let activeOnly = _.filter(state.adArray, { active: true });
        let notTagged = _.orderBy(_.filter(activeOnly, { tag: null }), ['published_at'], ['desc']);
        let TaggedLiked = _.orderBy(_.filter(activeOnly, (ad) => ad.tag == 'like'), ['published_at'], ['desc']);
        let TaggedContacted = _.orderBy(_.filter(activeOnly, (ad) => ad.tag == 'contact'), ['published_at'], ['desc']);
        let TaggedDisliked = _.orderBy(_.filter(activeOnly, (ad) => ad.tag == 'dislike'), ['published_at'], ['desc']);
        return _.take([...notTagged, ...TaggedLiked, ...TaggedContacted, ...TaggedDisliked], 6);
    },

    sortedAds(state) {
        return _.orderBy(state.adArray, ['active', 'published_at'], ['desc', 'desc']);
    },
    likedAds(state) {
        return _.orderBy(_.filter(state.adArray, (ad) => ad.tag === 'like'), ['published_at'], ['desc']);
    },
    dislikedAds(state) {
        return _.orderBy(_.filter(state.adArray, (ad) => ad.tag === 'dislike'), ['published_at'], ['desc']);
    },
    contactedAds(state) {
        return _.orderBy(_.filter(state.adArray, (ad) => ad.tag === 'contact'), ['published_at'], ['desc']);
    },
    getTitle(state) {
        return state.selectedAd ? state.selectedAd.ad_data.title : ''
    },
    isPolling(state) {
        return state.pollingTimer != null;
    }

};

const actions = {
    async targetAd({ commit }, id) {
        const ad = await AdsApi.get(id);
        if (ad) {
            commit("SET_SELECTED", ad);
            return ad;
        } else throw new Error("ad not found");
    },

    async updateAd({ commit }, { id, body }) {
        const ad = await AdsApi.update(id, body);
        commit('UPDATE_LIST', ad);
        commit("SET_SELECTED", ad);
    },
};

const mutations = {
    INIT_LIST_WITH_POLLING(state, ads) {
        state.adArray = [...ads];
        clearInterval(state.pollingTimer);
        //if (state.adArray.length == 0 && state.pollingTimer == null)
        state.pollingTimer = setInterval(() => {
            AdsApi.fetch().then((ads) => {
                state.adArray = [...ads];
                if (state.adArray.length > 0) {
                    clearInterval(state.pollingTimer);
                    state.pollingTimer = null;
                }
            });
        }, 5000);
    },

    INIT_LIST(state, ads) {
        state.adArray = [...ads];
    },

    SET_SELECTED(state, ad) {
        state.selectedAd = ad;
    },
    UPDATE_LIST(state, ad) {
        const index = state.adArray.findIndex((el) => el.id === ad.id);
        if (index !== -1) {
            state.adArray.splice(index, 1, ad);
        }
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};